@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Semibold-Web.woff2") format("woff2"), url("Graphik-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Medium-Web.woff2") format("woff2"), url("Graphik-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-RegularItalic-Web.woff2") format("woff2"), url("Graphik-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Regular-Web.woff2") format("woff2"), url("Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Clarendon LT W03 Roman";
  src: url("Clarendon.woff2") format("woff2"), url("Clarendon.woff") format("woff");
}
